import React from "react";
import { FaInstagram } from "react-icons/fa";
import fetchJsonp from 'fetch-jsonp';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';

import cameraman from "../../../assets/images/cameraman.png";
import cameramanMobile from "../../../assets/images/cameraman_mobile.png";

import Step from "../Step";
import StateMachine from "src/components/utils/StateMachine";
import WithUserForm from "../../Register/WithUserForm";

import styles from "../styles.module.css";
import stylesButton from "../../utils/css/button.module.css";

import Modal from "../../utils/Modal/index.js";
import { ReactComponent as Spinner } from "src/assets/spinner.svg";

const redirectUri =
  window.location.origin + "/register/instagram".replace("http://", "https://");
const instagramAuthURL = `https://www.instagram.com/oauth/authorize`;

const Loading = () => (
  <div className={styles.Loading}>
    <Spinner />
  </div>
);

class Form extends React.Component {
  state = {
    showModal: false,
    showVerifyModal: false,
    hasMediaAccess: false,
    accessToken: null,
  };

  componentDidMount() {
    this.getAuthenticateToken();
    this.getAccessToken();
  }

  showVerifyModal = () => {
    this.setState({
      showVerifyModal: true,
    });
    setTimeout(() => {
      this.waitFor((_) => this.state.hasMediaAccess).then((_) =>
        this.setState({ showVerifyModal: false })
      );
    }, 2000);
  };

  waitFor(conditionFunction) {
    const poll = (resolve) => {
      if (conditionFunction()) resolve();
      else setTimeout((_) => poll(resolve), 300);
    };
    return new Promise(poll);
  }

  getAccessToken = async () => {
    const hashString = window.location.search.substr(1, window.location.search.indexOf("&state") - 1);
    if (hashString.startsWith('code')) {
      this.showVerifyModal();
      const accessCode = hashString.substr(5);
      try {
        const response = await this.props.exchangeToken({
          variables: { code: accessCode },
        });
        let userAccessToken = response.data.exchangeToken.instagramToken;
        this.setState({ accessToken: userAccessToken });
        this.checkMediaAccess();
      }
      catch (error) {
        console.log(error)
      }
    }
  };

  checkMediaAccess = async () => {
    //Remove any previously set instagram access token
    localStorage.removeItem('instagram_access_token');
    localStorage.removeItem('user_name');
    localStorage.setItem('instagram_access_token', this.state.accessToken);
    const queryString = qs.stringify({
      access_token: this.state.accessToken,
    });
    const body = await fetchJsonp(
      'https://graph.instagram.com/v21.0/me/media/?' + queryString
    ).then((res) => res.json())
    .catch (error => console.log(error));

    if (!body.hasOwnProperty('error')) {
      const userQueryString = qs.stringify({
        fields: 'username',
        access_token: this.state.accessToken,
      });
      const userName = await fetchJsonp(
        'https://graph.instagram.com/v21.0/me/?' + userQueryString
      ).then((res) => res.json())
      .catch (error => console.log(error));
      if (!userName.hasOwnProperty('error')) {
        localStorage.setItem('user_name', userName.username);
      }
      this.setState({ hasMediaAccess: true });
    }
  };

  getAuthenticateToken = () => {
    const hashString = window.location.search.substr(window.location.search.indexOf("state="));
    const authToken = hashString.substr(6);

    if (!window.localStorage.getItem("token")) {
      window.localStorage.setItem("token", authToken);
    }
  }

  render() {
    const hashString = window.location.search.substr(1);

    if ( !this.state.showVerifyModal && this.state.hasMediaAccess ) {
      return <Redirect to='/register/instagram-photos' />;
    }

    return (
      <StateMachine.Consumer>
        {({ current, service }) => (
          <Step
            backgroundImage={
              window.innerWidth > 500
                ? cameraman
                : cameramanMobile
            }
            classNames={styles.step_fourth}>
            <form
              action={instagramAuthURL}
              method="GET"
              className={styles.ConnectInstagram}
            >
              <input
                type="hidden"
                name="client_id"
                value={process.env.REACT_APP_SCHMODEL_INSTAGRAM_APP_ID}
              />
              <input type="hidden" name="redirect_uri" value={redirectUri} />
              <input
                type="hidden"
                name="scope"
                value="instagram_business_basic"
              />
              <input type="hidden" name="response_type" value="code" />
              <input
                type="hidden"
                name="state"
                value={window.localStorage.getItem("token")}
              />
              <p className={styles.title}>
                Connect your Instagram
                <br /> account to continue
              </p>
              <button
                className={`${styles.wrap_instagram} ${
                  styles.wrap_connect_instagram
                }`}
              >
                <FaInstagram className="icon" size={25} />
                <div>Connect to Instagram</div>
              </button>
              <div className={styles.content}>
                We need this to verify your identity
                <br /> and allow you to access jobs <br /> without the usual
                hassle.
                <br />
                <br />
                You will need to make sure you allow Instagram's cookies and share your media with Schmodel.
                <br />
                <br />
                Don’t worry, your data is safe in <br />
                accordance with our Privacy Policy <br /> and Instagram’s Terms
                of Business.
              </div>
              {hashString?.startsWith("error") && (
                <div
                  style={{
                    color: "#e04344",
                    paddingLeft: 30,
                    fontSize: 14,
                    width: 300,
                  }}
                >
                  <strong style={{ display: "inline" }}>Oops! </strong>
                  Instagram authorization failed.
                  <br /> Please click on Connect to Instagram, then make sure you accept Instagram's cookies,
                  and click 'Allow' to share your media with Schmodel.
              </div>
              )}
              {this.state.showVerifyModal && (
                <Modal
                  backgroundStyle={styles.ModalBackground}
                  modalContentStyle={styles.ModalContent}
                  showLogo={false}
                  showClose={false}
                  showFooter={false}
                >
                  <h2 className={styles.h2Modal}>Verifying access</h2>
                  <p className={styles.pModal} style={{ display: "block" }}>
                    Please wait while we verify access <br />
                    to your Instagram profile and media.
                  </p>
                  <Loading />
                </Modal>
              )}
            </form>
            <button
              className={`${stylesButton.button} ${stylesButton.button_back}`}
              onClick={() => service.send("BACK")}
            >
              Back
            </button>
          </Step>
        )}
      </StateMachine.Consumer>
    );
  }
}

class AccessInstagram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerifyModal: false,
      hasMediaAccess: false,
      accessToken: null,
    };
  }
  
  render() {
    return <WithUserForm render={(props) => <Form {...props} />} />;
  }
}

export default AccessInstagram;
